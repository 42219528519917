import React from "react";
import Layout from "../layout";
import Seo from "../components/seo";
import ErrorImg from "../assets/icons/Error404";
import {
  SBtnHome,
  SErrorContainer,
  SErrorContent,
  SErrorImg,
  SLink,
} from "../styles/error";
import Typography from "@mui/material/Typography";

function NotFoundPage() {
  return (
    <Layout footerWrapper="footer-wrapper">
      <Seo title="404: Not found" />
      <div className="main-padding">
        <div className="main-wrapper">
          <SErrorImg>
            <ErrorImg />
          </SErrorImg>
          <SErrorContainer>
            <SErrorContent>
              <Typography variant="h2">Something Went Wrong</Typography>
              <Typography variant="body1">Come back to the homepage</Typography>
              <SLink to="/">
                <SBtnHome>Back to Homepage</SBtnHome>
              </SLink>
            </SErrorContent>
          </SErrorContainer>
        </div>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
