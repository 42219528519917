import React from "react";

const Error404 = () => {
  return (
    <svg
      width="824"
      height="559"
      viewBox="0 0 824 559"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M401.559 140.903V140.182H232.458C230.304 140.179 228.562 138.437 228.559 136.283V37.7983C228.562 35.6455 230.304 33.9027 232.458 33.8986H401.559C403.712 33.9027 405.455 35.6455 405.459 37.7983V136.283C405.455 138.436 403.712 140.178 401.559 140.182V141.623C404.509 141.623 406.899 139.234 406.9 136.283V37.7983C406.899 34.8486 404.509 32.4572 401.559 32.4572H232.458C229.508 32.4572 227.118 34.8486 227.117 37.7983V136.283C227.119 139.233 229.508 141.623 232.458 141.623H401.559V140.903Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M227.841 53.4193H406.183V51.9779H227.841V53.4193Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M242.21 43.0461H241.49C241.487 44.2216 240.536 45.1743 239.36 45.1757C238.185 45.1736 237.232 44.2216 237.23 43.0461C237.232 41.8699 238.185 40.9185 239.36 40.9165C240.536 40.9192 241.488 41.8699 241.49 43.0461H242.931C242.93 41.0743 241.333 39.4765 239.36 39.4751C237.388 39.4765 235.789 41.075 235.789 43.0461C235.789 45.0185 237.388 46.617 239.36 46.617C241.333 46.617 242.93 45.0185 242.931 43.0461H242.211H242.21Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M253.935 43.0461H253.215C253.212 44.2216 252.261 45.1743 251.085 45.1757C249.909 45.1736 248.956 44.2216 248.955 43.0461C248.957 41.8699 249.909 40.9185 251.085 40.9165C252.261 40.9192 253.212 41.8699 253.215 43.0461H254.656C254.655 41.0743 253.058 39.4765 251.085 39.4751C249.112 39.4765 247.514 41.075 247.514 43.0461C247.514 45.0185 249.113 46.617 251.085 46.617C253.058 46.617 254.655 45.0185 254.656 43.0461H253.936H253.935Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M265.665 43.0461H264.945C264.942 44.2216 263.991 45.1743 262.815 45.1757C261.64 45.1736 260.686 44.2216 260.686 43.0461C260.687 41.8699 261.64 40.9185 262.815 40.9165C263.991 40.9192 264.942 41.8699 264.945 43.0461H266.386C266.386 41.0743 264.788 39.4765 262.815 39.4751C260.843 39.4765 259.244 41.075 259.244 43.0461C259.244 45.0185 260.843 46.617 262.815 46.617C264.788 46.617 266.386 45.0185 266.386 43.0461H265.666H265.665Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M134.282 152.172V152.892H176.456C180.929 152.892 184.553 149.268 184.554 144.795V102.622C184.553 98.1489 180.929 94.5239 176.456 94.5226H134.282C129.811 94.5239 126.185 98.1489 126.185 102.622V144.795C126.185 149.268 129.81 152.892 134.282 152.892V151.451C132.442 151.451 130.782 150.707 129.576 149.501C128.37 148.295 127.626 146.635 127.626 144.794V102.621C127.626 100.781 128.369 99.1208 129.576 97.9138C130.782 96.7075 132.442 95.964 134.282 95.9633H176.456C178.295 95.9646 179.956 96.7082 181.162 97.9138C182.369 99.1201 183.112 100.781 183.114 102.621V144.794C183.112 146.635 182.368 148.294 181.162 149.501C179.956 150.707 178.295 151.451 176.456 151.451H134.282V152.171V152.172Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M171.671 123.709H169.51C169.508 127.622 167.93 131.143 165.367 133.709C162.801 136.273 159.279 137.851 155.367 137.852C152.107 137.851 149.118 136.754 146.726 134.905L166.563 115.069C168.411 117.459 169.508 120.448 169.51 123.709H173.833C173.832 113.51 165.566 105.244 155.367 105.242C145.168 105.244 136.903 113.51 136.901 123.709C136.903 133.908 145.168 142.174 155.367 142.176C165.567 142.174 173.832 133.908 173.833 123.709H171.672H171.671ZM143.746 131.771C142.155 129.484 141.225 126.712 141.225 123.709C141.225 119.796 142.804 116.275 145.366 113.709C147.933 111.145 151.454 109.567 155.367 109.566C158.37 109.567 161.143 110.497 163.43 112.088L143.747 131.772L143.746 131.771Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M561.243 139.222L549.436 126.987H367.728C359.226 126.987 352.333 120.094 352.333 111.591V17.1168C352.333 8.61479 359.226 1.72162 367.728 1.72162H550.252C558.754 1.72162 565.647 8.61479 565.647 17.1168V137.444C565.647 139.748 562.843 140.879 561.243 139.222Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M561.242 139.222L561.76 138.723L549.953 126.487C549.818 126.346 549.629 126.266 549.435 126.266H367.727C363.674 126.266 360.009 124.625 357.351 121.968C354.694 119.31 353.053 115.646 353.053 111.592V17.1176C353.053 13.062 354.695 9.39879 357.351 6.74021C360.009 4.08436 363.674 2.44274 367.727 2.44274H550.251C554.305 2.44274 557.969 4.08436 560.627 6.74021C563.284 9.39879 564.925 13.062 564.925 17.1176V137.445C564.922 138.019 564.701 138.456 564.358 138.78C564.012 139.103 563.537 139.291 563.064 139.29C562.602 139.287 562.153 139.124 561.76 138.722L561.242 139.222L560.723 139.721C561.382 140.407 562.242 140.733 563.064 140.731C563.909 140.729 564.724 140.407 565.34 139.833C565.957 139.263 566.369 138.416 566.366 137.443V17.1162C566.365 8.21507 559.15 1 550.25 1H367.727C358.826 1 351.61 8.21439 351.61 17.1162V111.591C351.61 120.491 358.826 127.706 367.727 127.707H549.128L560.722 139.722L561.241 139.222H561.242Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M732.461 317.92V318.641H774.634C779.107 318.641 782.731 315.015 782.731 310.542V268.37C782.731 263.897 779.107 260.271 774.634 260.271H732.461C727.988 260.271 724.363 263.897 724.363 268.37V310.542C724.363 315.015 727.988 318.641 732.461 318.641V317.199C730.62 317.199 728.961 316.457 727.754 315.25C726.548 314.042 725.804 312.382 725.804 310.541V268.37C725.804 266.528 726.547 264.869 727.754 263.662C728.96 262.455 730.62 261.712 732.461 261.712H774.634C776.474 261.712 778.134 262.455 779.341 263.662C780.547 264.869 781.29 266.529 781.29 268.37V310.541C781.29 312.382 780.547 314.041 779.341 315.25C778.134 316.456 776.474 317.199 774.634 317.199H732.461V317.92V317.92Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M736.856 302.938L770.241 302.927L753.538 274.019L736.856 302.938H736.856ZM771.898 305.443C771.682 305.519 771.453 305.56 771.216 305.56L735.884 305.571C735.146 305.573 734.457 305.175 734.088 304.537C733.719 303.898 733.719 303.104 734.088 302.464L751.744 271.859C752.112 271.219 752.795 270.823 753.536 270.821C754.275 270.821 754.961 271.218 755.332 271.857L773.01 302.451C773.379 303.09 773.379 303.884 773.01 304.524C772.759 304.958 772.36 305.282 771.897 305.443H771.898Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M753.543 296.855C752.875 296.855 752.325 296.329 752.294 295.66L751.579 283.618C751.529 282.501 752.421 281.568 753.538 281.568C754.657 281.568 755.549 282.499 755.499 283.616L754.793 295.659C754.763 296.326 754.213 296.854 753.544 296.854L753.543 296.855Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M754.819 299.759C754.819 300.463 754.248 301.033 753.545 301.033C752.841 301.035 752.271 300.464 752.271 299.761C752.271 299.057 752.841 298.487 753.545 298.487C754.248 298.487 754.819 299.056 754.819 299.759Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M8.14194 216.197H7.4216C7.41886 217.572 6.30622 218.684 4.93114 218.688C3.55675 218.685 2.44274 217.572 2.44069 216.197C2.44274 214.822 3.55538 213.708 4.93114 213.707C6.30691 213.709 7.41955 214.822 7.4216 216.197H8.86297C8.86229 214.025 7.10243 212.266 4.93114 212.266C2.75917 212.266 1 214.026 1 216.197C1 218.369 2.75986 220.128 4.93114 220.128C7.10312 220.128 8.86229 218.369 8.86297 216.197H8.14263H8.14194Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M768.334 123.708H767.614C767.611 125.083 766.499 126.198 765.123 126.198C763.748 126.197 762.636 125.083 762.632 123.708C762.635 122.334 763.747 121.22 765.123 121.218C766.499 121.22 767.612 122.333 767.614 123.708H769.055C769.054 121.536 767.294 119.777 765.123 119.777C762.951 119.777 761.191 121.536 761.191 123.708C761.191 125.88 762.951 127.639 765.123 127.639C767.295 127.639 769.054 125.88 769.055 123.708H768.335H768.334Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M595 24.3858H594.28C594.277 25.7622 593.165 26.8749 591.789 26.8763C590.414 26.8749 589.302 25.7622 589.298 24.3858C589.301 23.0114 590.413 21.8988 591.789 21.8953C593.165 21.8981 594.278 23.0107 594.28 24.3858H595.721C595.719 22.2138 593.96 20.4547 591.789 20.4547C589.617 20.4547 587.858 22.2145 587.857 24.3858C587.859 26.5578 589.617 28.3169 591.789 28.3169C593.961 28.3169 595.72 26.5571 595.721 24.3858H595.001H595Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M761.191 228.736V238.681C761.191 239.079 761.514 239.401 761.912 239.401C762.31 239.401 762.632 239.079 762.632 238.681V228.736C762.632 228.339 762.31 228.015 761.912 228.015C761.514 228.015 761.191 228.338 761.191 228.736Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M766.886 232.989H756.943C756.545 232.989 756.223 233.31 756.223 233.709C756.223 234.106 756.545 234.43 756.943 234.43H766.886C767.283 234.43 767.607 234.106 767.607 233.709C767.607 233.31 767.283 232.989 766.886 232.989Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M290.919 6.04524V15.9886C290.919 16.3877 291.24 16.7089 291.639 16.7089C292.037 16.7089 292.36 16.3877 292.36 15.9886V6.04524C292.36 5.64747 292.037 5.32489 291.639 5.32489C291.24 5.32489 290.919 5.64747 290.919 6.04524Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M296.614 10.2961H286.669C286.271 10.2961 285.948 10.6187 285.948 11.0165C285.948 11.4142 286.271 11.7368 286.669 11.7368H296.614C297.012 11.7368 297.334 11.4142 297.334 11.0165C297.334 10.6187 297.012 10.2961 296.614 10.2961Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M68.3135 102.165V112.108C68.3135 112.506 68.6361 112.828 69.0338 112.828C69.4316 112.828 69.7542 112.506 69.7542 112.108V102.165C69.7542 101.768 69.4316 101.445 69.0338 101.445C68.6361 101.445 68.3135 101.768 68.3135 102.165Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M74.0084 106.417H64.0651C63.6659 106.417 63.3447 106.739 63.3447 107.137C63.3447 107.534 63.6659 107.857 64.0651 107.857H74.0084C74.4062 107.857 74.7288 107.535 74.7288 107.137C74.7288 106.739 74.4062 106.417 74.0084 106.417Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M52.1299 331.122V341.066C52.1299 341.464 52.4511 341.786 52.8502 341.786C53.2473 341.786 53.5706 341.464 53.5706 341.066V331.122C53.5706 330.723 53.2473 330.402 52.8502 330.402C52.4511 330.402 52.1299 330.723 52.1299 331.122Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M57.8201 335.373H47.8746C47.4776 335.373 47.1543 335.696 47.1543 336.094C47.1543 336.491 47.4776 336.814 47.8746 336.814H57.8201C58.2178 336.814 58.5404 336.491 58.5404 336.094C58.5404 335.696 58.2178 335.373 57.8201 335.373Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M615.824 62.3484V72.2931C615.824 72.6909 616.145 73.0135 616.545 73.0135C616.942 73.0135 617.265 72.6909 617.265 72.2931V62.3484C617.265 61.9506 616.942 61.6281 616.545 61.6281C616.145 61.6281 615.824 61.9506 615.824 62.3484Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M621.52 66.6004H611.575C611.177 66.6004 610.854 66.923 610.854 67.3208C610.854 67.7185 611.177 68.0411 611.575 68.0411H621.52C621.917 68.0411 622.24 67.7185 622.24 67.3208C622.24 66.923 621.917 66.6004 621.52 66.6004Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M126.837 274.505V273.785H27.3276C25.9867 273.782 24.9021 272.697 24.8987 271.356C24.9014 270.016 25.9867 268.931 27.3276 268.93H126.837C128.178 268.932 129.263 270.017 129.265 271.356C129.263 272.697 128.178 273.782 126.837 273.785V275.224C128.974 275.224 130.705 273.494 130.707 271.356C130.705 269.219 128.974 267.489 126.837 267.489H27.3276C25.1898 267.489 23.4594 269.219 23.458 271.356C23.4594 273.494 25.1898 275.224 27.3276 275.224H126.837V274.505Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M712.74 195.04V194.32H613.231C611.889 194.317 610.805 193.232 610.802 191.892C610.805 190.551 611.889 189.467 613.231 189.465H712.74C714.081 189.467 715.166 190.552 715.167 191.892C715.165 193.232 714.08 194.318 712.74 194.32V195.761C714.878 195.759 716.608 194.029 716.608 191.892C716.608 189.754 714.878 188.024 712.74 188.024H613.231C611.093 188.024 609.361 189.754 609.361 191.892C609.361 194.029 611.093 195.759 613.231 195.761H712.74V195.041V195.04Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M727.307 216.198H690.522C689.653 216.198 688.949 215.493 688.949 214.624C688.949 213.755 689.653 213.049 690.522 213.049H727.307C728.177 213.049 728.881 213.755 728.881 214.624C728.881 215.493 728.177 216.198 727.307 216.198Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M629.965 141.208H593.181C592.311 141.208 591.607 140.504 591.607 139.634C591.607 138.764 592.311 138.06 593.181 138.06H629.965C630.834 138.06 631.539 138.764 631.539 139.634C631.539 140.504 630.834 141.208 629.965 141.208Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M89.8512 246.684H61.712C60.8433 246.684 60.1387 245.98 60.1387 245.11C60.1387 244.242 60.8433 243.537 61.712 243.537H89.8512C90.7205 243.537 91.4245 244.242 91.4245 245.11C91.4245 245.98 90.7205 246.684 89.8512 246.684Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M657.81 107.791V108.511H688.276C691.618 108.511 694.325 105.804 694.325 102.462V71.9958C694.325 68.6531 691.618 65.946 688.276 65.944H657.81C654.468 65.946 651.76 68.6531 651.759 71.9958V102.462C651.76 105.804 654.468 108.511 657.81 108.511V107.07C655.266 107.066 653.206 105.007 653.199 102.462V71.9958C653.206 69.4507 655.265 67.3901 657.81 67.3853H688.276C690.82 67.3894 692.88 69.45 692.885 71.9958V102.462C692.88 105.007 690.821 107.066 688.276 107.07H657.81V107.79V107.791Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M670.493 89.025H674.853L675.568 85.493H671.21L670.494 89.025H670.493ZM678.872 85.493L678.156 89.025H682.086V92.1627H677.562L676.391 98.3437H673.04L674.229 92.1627H669.902L668.744 98.3437H665.47L666.597 92.1627H662.974V89.025H667.19L667.905 85.493H664.071V82.3116H668.471L669.643 76.1162H672.978L671.806 82.3116H676.194L677.38 76.1162H680.654L679.466 82.3116H683.121V85.493H678.873H678.872Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M690.438 401.224C690.42 388.387 695.915 375.637 705.257 366.834C709.862 362.494 715.373 359.142 721.305 356.955C724.652 355.722 740.687 354.513 740.687 354.513C740.687 354.513 738.819 366.749 735.626 372.916C728.179 387.294 713.452 399.381 690.438 401.224V401.224Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M737.597 437.747C743.395 430.638 751.772 425.682 760.795 424.025C769.816 422.368 779.41 424.024 787.353 428.61C787.611 428.759 787.892 428.94 787.961 429.231C788.043 429.575 787.796 429.903 787.559 430.168C780.107 438.513 768.554 443.01 757.42 441.9C752.53 441.413 742.27 439.267 737.597 437.747H737.597Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M750.482 418.228C750.494 407.037 754.215 396.991 760.785 390.416C767.354 383.842 776.718 380.792 786.712 381.973C787.036 382.011 787.402 382.076 787.665 382.374C787.975 382.728 787.991 383.235 787.973 383.662C787.404 397.137 780.647 408.292 770.342 412.77C765.817 414.736 755.541 417.565 750.482 418.228Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M767.862 398.212C772.296 391.07 777.688 384.524 783.851 378.811C786.331 376.512 788.956 374.334 791.933 372.734C801.571 367.556 813.463 369.159 823.828 372.666C819.707 380.495 814.33 388.158 806.423 392.127C802.923 393.883 799.085 394.832 795.264 395.682C787.681 397.371 775.567 397.207 767.863 398.212H767.862Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M762.355 379.52C743.069 369.418 740.221 348.348 739.734 340.555C739.429 335.672 739.747 330.242 743.816 326.727C767.712 335.138 773.812 351.315 762.355 379.519V379.52Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M679.245 376.482C680.741 366.22 682.124 355.195 677.455 345.934C675.782 342.614 673.4 339.699 670.809 337.033C664.537 330.577 656.332 325.241 647.332 325.226C645.714 334.84 647.666 345.013 652.731 353.344C657.793 361.675 665.924 368.095 675.202 371.086L679.246 376.482H679.245Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M735.331 443.598C724.577 440.006 715.445 431.821 710.697 421.526C705.951 411.231 705.658 398.97 709.908 388.46C710.097 387.994 710.346 387.481 710.825 387.327C711.278 387.182 711.759 387.421 712.173 387.659C728.808 397.219 740.19 425.037 735.331 443.599V443.598Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M690.129 386.921C687.024 377.946 687.188 367.88 690.586 359.011C693.982 350.143 700.587 342.542 708.894 337.939C712.279 336.064 715.96 334.651 718.963 332.209C719.98 336.718 720.665 341.305 721.008 345.914C721.347 350.474 721.345 355.126 720.134 359.535C717.006 370.932 706.5 378.681 695.948 384.003L690.128 386.921H690.129Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M676.194 385.193C658.876 387.29 637.55 380.301 628.294 363.988C657.907 348.936 676.194 371.963 676.194 385.193Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M686.3 423.834C682.417 410.535 672.197 399.625 659.885 395.638C647.572 391.653 633.522 394.703 623.567 403.523C623.153 403.89 622.708 404.358 622.747 404.935C622.77 405.28 622.963 405.578 623.154 405.853C629.772 415.423 640.333 421.075 651.132 423.457C661.932 425.84 673.082 425.27 684.076 424.447L686.3 423.835L686.3 423.834Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M664.035 352.919C673.02 361.894 678.621 373.28 682.806 385.133C687.138 397.402 690.3 410.11 692.819 422.865C698.135 449.769 699.542 477.003 702.638 504.182C702.682 504.568 703.273 504.588 703.256 504.182C702.072 476.505 699.098 448.413 693.584 421.255C690.892 407.998 687.504 394.747 682.668 382.096C678.488 371.17 672.881 360.752 664.33 352.623C664.138 352.439 663.846 352.729 664.036 352.919L664.035 352.919Z"
        fill="#151515"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M695.883 434.991C689.237 424.223 678.207 417.859 666.762 413.208C653.963 408.005 639.448 404.742 625.57 405.677C625.231 405.699 625.227 406.191 625.57 406.206C650.824 407.333 679.727 413.449 695.27 435.35C695.529 435.714 696.126 435.386 695.883 434.991Z"
        fill="#151515"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M690.64 409.71C690.532 399.563 689.469 389.395 691.468 379.348C693.327 370.011 697.052 361.553 702.636 353.858C702.797 353.636 702.437 353.36 702.255 353.565C688.7 368.807 688.543 390.6 690.313 409.71C690.332 409.916 690.641 409.924 690.639 409.71H690.64Z"
        fill="#151515"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M650.45 369.558C657.862 370.478 664.41 375.457 670.01 380.057C675.592 384.64 680.573 389.906 686.135 394.498C686.361 394.684 686.676 394.387 686.462 394.172C680.853 388.563 674.836 383.13 668.561 378.278C663.291 374.202 657.271 370.04 650.449 369.411V369.558H650.45Z"
        fill="#151515"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M728.586 468.97C732.643 447.894 741.124 428.606 755.19 412.235C761.971 404.344 769.779 397.325 778.39 391.482C787.424 385.349 797.066 381.175 807.236 377.339C807.649 377.183 807.493 376.551 807.051 376.666C786.597 381.992 767.752 396.127 754.253 412.034C740.784 427.907 730.563 448.061 728.055 468.899C728.019 469.2 728.529 469.27 728.586 468.971V468.97Z"
        fill="#151515"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M752.007 343.045C762.143 364.377 765.894 388.565 755.633 410.513C755.431 410.947 756.03 411.317 756.284 410.893C768.599 390.395 762.629 362.805 752.521 342.744C752.348 342.401 751.843 342.703 752.007 343.046L752.007 343.045Z"
        fill="#151515"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M765.201 454.97V471.854C765.201 538.801 747.575 557.844 747.575 557.844H692.993C692.993 557.844 675.365 538.801 675.365 471.854V454.97H765.201Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M117.154 558.066L163.437 336.151L161.321 335.708L115.036 557.624L117.154 558.066Z"
        fill="#151515"
      />
      <path
        d="M209.719 557.624L163.436 335.708L161.32 336.151L207.604 558.066L209.719 557.624Z"
        fill="#151515"
      />
      <path
        d="M584.619 558.066L630.902 336.151L628.786 335.708L582.501 557.624L584.619 558.066Z"
        fill="#151515"
      />
      <path
        d="M677.186 557.624L630.901 335.708L628.786 336.151L675.069 558.066L677.186 557.624Z"
        fill="#151515"
      />
      <path
        d="M117.596 335.929H670.444V322.652H117.596V335.929Z"
        fill="#151515"
      />
      <path
        d="M643.797 297.344H448.429C444.943 297.344 442.304 294.195 442.914 290.763L465.362 164.501C466.072 160.5 469.55 157.586 473.612 157.586H668.98C672.466 157.586 675.105 160.737 674.495 164.168L652.047 290.43C651.336 294.429 647.859 297.343 643.797 297.343V297.344Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M648.34 297.344H452.972C449.486 297.344 446.847 294.195 447.459 290.763L469.905 164.501C470.617 160.5 474.095 157.586 478.155 157.586H673.523C677.009 157.586 679.648 160.737 679.038 164.168L656.59 290.43C655.88 294.429 652.402 297.343 648.34 297.343V297.344Z"
        fill="#151515"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M499.557 297.344H612.396C614.742 297.344 616.749 295.661 617.16 293.351L630.125 220.426C630.477 218.444 628.954 216.625 626.94 216.625H514.102C511.756 216.625 509.746 218.307 509.336 220.617L496.371 293.542C496.018 295.525 497.543 297.344 499.556 297.344H499.557Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M615.096 297.344H502.257C500.244 297.344 498.719 295.525 499.073 293.542L512.037 220.617C512.448 218.307 514.456 216.625 516.802 216.625H629.64C631.654 216.625 633.178 218.444 632.826 220.426L619.861 293.351C619.45 295.661 617.441 297.344 615.095 297.344H615.096Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M544.286 297.344H590.081V254.454H544.286V297.344Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M576.376 302.637C571.244 302.637 565.818 296.652 564.258 289.268C562.697 281.885 565.591 275.9 570.723 275.9C575.856 275.9 581.281 281.885 582.842 289.268C584.403 296.652 581.509 302.637 576.376 302.637ZM587.46 254.455H544.286V266.775L556.073 315.687H601.895L587.46 254.455Z"
        fill="#151515"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M519.031 315.687H621.618V322.652H512.064C512.064 318.805 515.183 315.687 519.031 315.687V315.687Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M539.607 322.652H621.622V315.686H539.607V322.652Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M578.862 302.637C573.728 302.637 568.303 296.652 566.742 289.268C565.181 281.885 568.075 275.9 573.209 275.9C578.341 275.9 583.766 281.885 585.327 289.268C586.888 296.652 583.993 302.637 578.862 302.637ZM590.108 254.455H544.286L558.723 315.687H604.546L590.108 254.455Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M483.109 185.285H480.397L483.109 170.022H485.823L483.109 185.285Z"
        fill="white"
      />
      <path
        d="M490.958 185.285H488.245L490.958 170.022H493.672L490.958 185.285Z"
        fill="white"
      />
      <path
        d="M498.815 185.285H496.101L498.815 170.022H501.528L498.815 185.285Z"
        fill="white"
      />
      <path
        d="M506.672 185.285H503.957L506.672 170.022H509.385L506.672 185.285Z"
        fill="white"
      />
      <path
        d="M514.525 185.285H511.812L514.525 170.022H517.239L514.525 185.285Z"
        fill="white"
      />
      <path
        d="M586.193 185.285H583.479L586.193 170.022H588.905L586.193 185.285Z"
        fill="white"
      />
      <path
        d="M594.445 185.285H591.731L594.445 170.022H597.157L594.445 185.285Z"
        fill="white"
      />
      <path
        d="M602.698 185.285H599.984L602.698 170.022H605.412L602.698 185.285Z"
        fill="white"
      />
      <path
        d="M610.949 185.285H608.235L610.949 170.022H613.662L610.949 185.285Z"
        fill="white"
      />
      <path
        d="M619.201 185.285H616.487L619.201 170.022H621.915L619.201 185.285Z"
        fill="white"
      />
      <path
        d="M627.458 185.285H624.744L627.458 170.022H630.172L627.458 185.285Z"
        fill="white"
      />
      <path
        d="M635.711 185.285H632.998L635.711 170.022H638.425L635.711 185.285Z"
        fill="white"
      />
      <path
        d="M643.964 185.285H641.249L643.964 170.022H646.677L643.964 185.285Z"
        fill="white"
      />
      <path
        d="M652.216 185.285H649.503L652.216 170.022H654.929L652.216 185.285Z"
        fill="white"
      />
      <path
        d="M660.468 185.285H657.754L660.468 170.022H663.181L660.468 185.285Z"
        fill="white"
      />
      <path
        d="M341.486 322.652H501.857V319.8H341.486V322.652Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M346.972 319.801H358.464V317.502H346.972V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M364.37 319.801H375.862V317.502H364.37V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M381.763 319.801H393.254V317.502H381.763V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M399.161 319.801H410.654V317.502H399.161V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M416.555 319.801H428.048V317.502H416.555V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M433.953 319.801H445.446V317.502H433.953V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M451.352 319.801H462.844V317.502H451.352V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M468.743 319.801H480.237V317.502H468.743V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M486.143 319.801H497.635V317.502H486.143V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M343.589 322.652H503.957V319.8H343.589V322.652Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M349.067 319.801H360.559V317.502H349.067V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M366.466 319.801H377.959V317.502H366.466V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M383.86 319.801H395.353V317.502H383.86V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M401.259 319.801H412.751V317.502H401.259V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M418.657 319.801H430.15V317.502H418.657V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M436.049 319.801H447.542V317.502H436.049V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M453.447 319.801H464.941V317.502H453.447V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M470.84 319.801H482.333V317.502H470.84V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M488.241 319.801H499.734V317.502H488.241V319.801Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M92.4509 430.231C94.4445 422.32 93.5581 413.716 89.994 406.378C86.4312 399.041 80.2174 393.024 72.7665 389.701C69.7313 388.347 66.4761 387.411 63.726 385.543C63.1888 389.477 62.9414 393.451 62.9892 397.422C63.0364 401.349 63.3829 405.33 64.7457 409.015C68.2667 418.536 77.8294 424.39 87.2547 428.163L92.4496 430.231L92.4509 430.231Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M93.6077 455.762C93.8989 437.336 94.1415 416.293 79.6437 402.569C79.414 402.352 79.1003 402.677 79.302 402.912C85.8028 410.467 90.4605 419.597 91.8458 429.542C93.0569 438.236 92.5709 447.034 93.2817 455.761C93.2988 455.968 93.6043 455.974 93.6084 455.761L93.6077 455.762Z"
        fill="#151515"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M70.0469 479.931C68.6623 468.994 62.5919 458.729 53.6724 452.247C49.2772 449.05 44.2156 446.795 38.9224 445.579C35.9364 444.891 22.1426 445.609 22.1426 445.609C22.1426 445.609 25.0677 455.833 28.4617 460.738C36.3718 472.176 50.2374 480.869 70.0469 479.931Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M57.9838 444.706C57.6263 438.086 56.4091 431.517 54.3752 425.231C53.5571 422.703 52.5983 420.201 51.1938 417.97C46.6421 410.742 38.1169 407.36 29.9183 405.871C29.8172 412.851 30.6175 420.188 34.4523 425.925C36.1486 428.461 38.3663 430.57 40.6066 432.602C45.0558 436.637 53.2052 441.099 57.9838 444.706Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M104.536 420.601C103.252 411.786 102.063 402.317 106.074 394.361C107.511 391.51 109.558 389.005 111.784 386.714C117.171 381.17 124.219 376.586 131.949 376.573C133.339 384.831 131.661 393.57 127.313 400.726C122.964 407.882 112.505 418.031 104.536 420.601Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M90.3767 471.82C98.5752 473.518 107.446 471.686 114.298 466.875C121.15 462.064 125.89 454.347 127.079 446.059C127.131 445.692 127.163 445.271 126.915 444.996C126.678 444.733 126.282 444.707 125.93 444.706C111.759 444.634 94.1643 458.166 90.376 471.82H90.3767Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M61.2199 439.945C72.8957 433.373 80.3965 420.207 80.6172 405.901C72.1897 405.433 65.8454 410.62 62.1753 415.09C57.0639 421.313 56.6326 432.249 61.2199 439.945Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M69.7014 463.035C66.5828 455.267 67.5622 445.985 72.2335 439.04C76.9055 432.096 85.1341 427.69 93.5042 427.651C93.8521 427.649 94.2533 427.681 94.4556 427.966C94.5752 428.135 94.5909 428.353 94.5998 428.559C94.921 435.732 91.7649 442.722 87.2419 448.297C82.7202 453.873 76.8946 458.226 71.0525 462.398L69.7007 463.036L69.7014 463.035Z"
        fill="#24CA49"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M117.334 400.087C101.78 414.863 96.3374 438.552 92.2053 458.798C87.4322 482.183 84.7579 506.442 83.8762 530.29C83.8619 530.697 84.4503 530.676 84.4961 530.29C87.29 506.944 88.391 483.534 92.9667 460.407C97.0509 439.773 102.292 415.82 117.63 400.381C117.819 400.191 117.529 399.903 117.335 400.087H117.334Z"
        fill="#151515"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M69.6932 475.385C69.4437 468.384 70.6349 462.067 72.2151 455.291C72.282 455.01 71.8754 454.809 71.7325 455.086C68.7999 460.741 67.9524 469.252 68.9967 475.479C69.063 475.875 69.7068 475.781 69.6932 475.385V475.385Z"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M70.6617 415.791C61.33 424.249 58.9483 441.977 62.7584 453.464C62.8514 453.745 63.2703 453.631 63.2034 453.34C60.4436 441.344 61.3519 425.052 70.7656 415.896L70.6617 415.791Z"
        fill="#151515"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M74.062 516C74.5718 499.74 71.8914 482.685 67.4135 467.091C62.9978 451.712 55.9324 435.032 44.1929 423.748C43.8614 423.429 43.422 423.899 43.6988 424.243C48.9333 430.715 53.798 437.117 57.643 444.543C61.5701 452.128 64.584 460.159 66.8981 468.374C71.3104 484.039 72.555 499.847 73.522 516C73.5432 516.346 74.0524 516.35 74.0626 516L74.062 516Z"
        fill="#151515"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M121.741 487.669V499.187C121.741 544.854 106.601 557.845 106.601 557.845H59.7164C59.7164 557.845 44.5762 544.854 44.5762 499.187V487.669H121.742H121.741Z"
        fill="#5A32FB"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M398.025 244.973L396.616 217.286L373.021 282.229L399.852 280.863L399.847 280.752L384.746 281.521L398.025 244.973Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M427.188 303.707L428.016 319.968L439.769 319.802L438.919 303.108L427.188 303.707Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M420.943 181.056L421.523 192.432L425.957 279.534L438.528 278.894L439.754 302.957L451.485 302.359L450.254 278.185L437.683 278.827L431.861 164.485L402.852 165.962L402.811 166.075L420.136 165.192L420.943 181.056Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M399.848 280.863L373.016 282.229L396.611 217.286L398.021 244.972L399.843 280.752L399.848 280.863ZM438.918 303.109L439.757 303.066L439.752 302.957L438.526 278.893L425.955 279.534L421.521 192.432L422.353 208.779L417.809 187.295L420.942 181.056L420.135 165.193L402.809 166.075L391.125 166.671L376.1 207.939L378.943 212.975L372.954 216.581L348.6 283.473L349.831 307.647L401.08 305.037L401.859 320.339L428.015 319.97L427.187 303.708L438.918 303.11V303.109Z"
        fill="#5A32FB"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M373.021 282.229L396.616 217.286L398.025 244.973L391.92 261.772L387.493 273.962L384.746 281.521L399.847 280.752L399.852 280.863L373.021 282.229Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M428.016 319.968L427.188 303.706C431.441 303.489 435.325 303.291 438.919 303.108L439.769 319.802L428.016 319.968Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M343.906 312.462L338.154 224.542C336.509 199.398 323.201 185.086 300.387 184.561C316.019 189.08 325.09 202.371 326.428 222.839L332.18 310.759C332.425 314.488 332.373 318.007 332.052 321.32L343.927 321.152C344.104 318.392 344.104 315.498 343.906 312.462Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M283.942 226.394C283.241 215.689 287.105 210.601 293.878 209.196C291.477 207.516 288.44 206.89 284.894 207.123C276.44 207.676 271.427 212.613 272.216 224.69L278.19 315.989C278.341 318.294 278.67 320.309 279.158 322.064L290.445 321.905C290.194 320.622 290.016 319.22 289.915 317.692L283.943 226.394H283.942Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M320.555 235.961L326.847 229.209L327.354 236.964L320.555 235.961ZM332.182 310.759L326.43 222.838C325.091 202.371 316.021 189.081 300.389 184.56C295.36 183.107 289.66 182.554 283.313 182.969C257.228 184.674 243.989 201.066 245.758 228.118L250.983 307.973L257.327 310.315L251.51 316.038C251.656 318.262 251.901 320.391 252.228 322.444L279.158 322.064C278.67 320.309 278.341 318.294 278.19 315.989L272.216 224.691C271.426 212.613 276.439 207.676 284.894 207.123C288.439 206.89 291.477 207.516 293.877 209.196C297.2 211.521 299.292 215.877 299.751 222.889L305.724 314.188C305.914 317.095 305.76 319.579 305.311 321.697L332.054 321.321C332.376 318.007 332.427 314.489 332.182 310.76V310.759Z"
        fill="#5A32FB"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M277.333 302.901L276.952 297.092L277.333 302.901ZM276.019 282.807L272.216 224.691C272.162 223.865 272.135 223.073 272.135 222.313C272.135 223.073 272.162 223.865 272.216 224.691L276.019 282.807ZM293.878 209.197C293.857 209.182 293.836 209.167 293.815 209.153C293.836 209.167 293.857 209.182 293.878 209.197ZM293.783 209.132C293.764 209.118 293.744 209.106 293.725 209.093C293.744 209.106 293.764 209.118 293.783 209.132ZM284.893 207.123C284.917 207.121 284.94 207.12 284.964 207.119C284.94 207.12 284.917 207.122 284.893 207.123ZM285.025 207.115C285.451 207.089 285.87 207.076 286.281 207.076C285.871 207.076 285.451 207.089 285.025 207.115ZM286.289 207.076C286.287 207.076 286.285 207.076 286.283 207.076C286.285 207.076 286.287 207.076 286.289 207.076Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M332.104 320.801C332.135 320.443 332.164 320.082 332.188 319.718C332.176 320.155 332.159 320.525 332.138 320.801C332.128 320.801 332.114 320.801 332.104 320.801Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M401.864 320.336L401.857 320.22C401.874 320.22 401.888 320.221 401.904 320.221C401.877 320.295 401.864 320.336 401.864 320.336Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M401.863 320.337C401.863 320.337 401.877 320.296 401.904 320.221C403.677 320.237 405.411 320.252 407.041 320.264L401.863 320.337Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M332.049 321.319C332.066 321.147 332.082 320.975 332.097 320.801C332.107 320.801 332.121 320.801 332.131 320.801C332.11 321.087 332.082 321.271 332.049 321.319H332.049Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M177.628 282.527L177.646 282.413L162.804 280.111L183.1 247.247L187.386 219.619L151.079 278.409L177.628 282.527Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M220.232 178.383L213.204 223.695V223.693L203.458 286.533L215.896 288.462L212.204 312.268L223.911 314.083L227.621 290.165L215.184 288.236L232.731 175.098L204.028 170.647L203.963 170.752L221.005 173.396L220.232 178.383Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M199.75 310.453L197.771 323.211L209.786 323.043L211.456 312.268L199.75 310.453Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M183.097 247.248L177.642 282.413L177.624 282.526L151.075 278.408L187.382 219.619L183.097 247.247V247.248ZM211.455 312.268L212.186 312.381L212.204 312.268L215.896 288.462L203.458 286.533C209.306 248.826 215.164 211.055 221.005 173.396L203.963 170.752L192.302 168.944L143.609 247.673L147.584 251.073L139.613 254.131L126.916 274.662L123.206 298.581L173.915 306.446L171.257 323.586L197.769 323.212L199.748 310.453L211.455 312.269V312.268Z"
        fill="#5A32FB"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M220.231 178.383L213.203 223.693V223.695L220.231 178.383Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M213.203 223.695V223.693L220.231 178.383L213.203 223.695Z"
        fill="white"
        stroke="#151515"
        strokeWidth="0.19998"
        strokeMiterlimit="22.9256"
      />
    </svg>
  );
};

export default Error404;
